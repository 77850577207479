.main_page_wrap {
    position: relative;
    padding: 20px ;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-left: 250px;
}

@media screen and (max-width : 1024px) {
    .main_page_wrap {
        margin-left: 0px;
    }
}
