.content {
  border: 1px solid #ced4da;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1% 2%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
}

.card {
  border-radius: 4px;
  background-color: #ebebeb;
  margin: 4px;
  padding: 3%;
  width: 292px;
  height: 300px;
}
.title {
  font-weight: 600;
  font-size: 0.9rem;
}
.value {
  font-size: 0.8rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.rowItem {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
