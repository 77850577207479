.main_wrap {
  background: #fff5f5;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  padding: 7px 10px;
  height: 30px;
  align-items: center;
  font-size: 1rem;
  line-height: 16px;
  color: #fa5252;
  margin: 0 6px 6px 0;
}
