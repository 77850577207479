.container {
  .headers {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    border: 1px solid #ced4da;
    box-sizing: border-box;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    margin-top: 31px;
    padding: 15px;
  }
}

.row {
  display: flex;
  color: black;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  border: 1px solid #ced4da;
  border-top: none;
  height: 51px;

  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    cursor: pointer;
  }

  .item {
    height: 21px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: 24px;
  }
}

.checked {
  background: #f1f3f5;
}

.icon {
  padding: 0;
  margin-right: 6px;

  svg {
    width: 18px;
    height: 18px;
  }
}

.likeIcon {
  padding: 0;
  margin-right: 6px;

  svg {
    width: 18px;
    height: 18px;

    path {
      fill: #878787;
    }
  }
}

.likeIcon_active {
  padding: 0;
  margin-right: 6px;

  svg {
    width: 18px;
    height: 18px;

    path {
      fill: red;
    }
  }
}

.threePoints {
  padding: 0;

  svg {
    width: 25px;
    height: 25px;
  }
}
