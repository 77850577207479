.main {
  padding: 0 30px;
  width: 416px;
  min-height: 146px;
  max-height: 1353px;
  box-shadow: 0 1px 12px rgba(0, 0, 0, 0.1);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  border-radius: 4px;

  .header {
    font-size: 15px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 88px;
    border-bottom: 1px solid #ced4da;
  }

  .notifications {
    overflow-y: scroll;
    max-height: 620px;

    &::-webkit-scrollbar {
      width: 3px;
      background: #c7c7c7;
    }

    &::-webkit-scrollbar-thumb {
      background: #2b2b2b;
      border-radius: 4px;
    }

    .item {
      min-height: 88px;
      border-bottom: 1px solid #ced4da;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .line {
        display: flex;
        align-items: center;

        .title {
          font-weight: 700;
          font-size: 15px;
        }

        .date {
          margin-left: 30px;
          font-size: 14px;
          font-weight: 400;
          color: #868e96;
        }
      }

      &_header {
        display: flex;
        align-items: center;
      }
    }
  }

  .pagination {
    height: 88px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #868e96;
    font-size: 13px;
  }
}

@media screen and (max-width: 425px) {
  .main {
    width: 300px;
  }
}
