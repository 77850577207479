.main_page_wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.title_wrap {
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #868E96;
  margin-top: 10px;

}
.data_wrap {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #212529;
  margin-top: 5px;
}