.container {
  padding: 30px;
  width: 416px;
  background: white !important;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);

  .header {
    font-size: 18px;
    height: 55px;
    display: flex;
    justify-content: center;
    width: 356px;
    font-weight: 700;
    border-bottom: 1px solid #CED4DA;
  }

  .body {
    .item {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      height: 68px;
      width: 356px;

      .title {
        color: #868E96;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .footer {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }
}