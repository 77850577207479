//styles for main component
.root {
  .filters {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  .pagination {
    margin-top: 16px;
  }
}
//styles for item component
.dayItem {
  .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f8f9fa;
    padding: 0 10px;
    border-bottom: 1px solid #ced4da;
    min-height: 50px;

    .item {
      display: flex;
      align-items: center;

      svg {
        margin-left: 8px;
        width: 14px;
      }

      .circle {
        margin-left: 8px;
        width: 10px;
        height: 10px;
        border-radius: 25px;
      }
    }
  }
}

.iudDay {
  padding: 15px 270px;

  .item {
    width: 45%;
    margin-bottom: 20px;

    .title {
      font-size: 15px;
      font-weight: 600;
    }
  }

  .divider {
    margin-bottom: 20px;
    border-top: 1px solid #ced4da;
    height: 1px;
    width: 100%;
  }

  .btns {
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 425px) {
  .iudDay {
    padding: 3%;

    .item {
      width: 100%;
      margin-bottom: 4%;

      .title {
        font-size: 0.8rem;
        font-weight: 600;
      }
    }

    .divider {
      margin-bottom: 20px;
      border-top: 1px solid #ced4da;
      height: 1px;
      width: 100%;
    }

    .btns {
      display: flex;
      align-items: center;
    }
  }
}
