.main_page_wrap {
    position: relative;
    padding: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-left: 250px;

    .filters {
        display: flex;
        align-items: flex-end;
        width: 50%;
      }
}

.title_wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.btn_wrap {
    display: flex;
    width: 453px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.btn_download {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 31px;
}

.title_content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    border: 1px solid #ced4da;
    box-sizing: border-box;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    margin-top: 31px;
    padding: 15px 30px;
}

.buttons{
    border-radius: 4px !important;
    // float: right;
    margin-bottom: 10px;
    cursor: pointer;
}
.findApplication {
    width: 120px;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #212529 !important;
    color: white !important;
    border-radius: 4px !important;
    
  
    &:hover {
      background: #212529 !important;
      color: white !important;
    }
  }

@media screen and (max-width: 1024px) {
    .main_page_wrap {
        margin-left: 0;
    }

    .items {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}