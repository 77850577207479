.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.notes-month {
  font-size: 28px;
  text-align: center;
}

.notes-month section {
  font-size: 28px;
}

* {
  font-family: SF Pro Display;
}

.moreBtn {
  background: #E5F3FF;
  color: #0085FF;
  width: 55px;
  height: 30px;
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}