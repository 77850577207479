.container {
  padding: 0 30px;
  width: 416px;
  background: white !important;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);

  .header {
    font-size: 18px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 356px;
    font-weight: 700;
    border-bottom: 1px solid #CED4DA;
  }

  .body {
    .item {
      margin-top: 30px;
      justify-content: space-between;
      width: 356px;

      .title {
        color: #868E96;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .footer {
    height: 80px;
    border-top: 1px solid #CED4DA;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    /*  .btns {
        width: 300px;
        display: flex;
        justify-content: space-between;
      }*/
  }
}