.CustomDropDown{
    z-index: 9999999;
}
.post{
    display: flex;
    flex-direction: row;

    .post__leftSide{
        width: 300px;
        padding: 20px;
        margin: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between ;

        .post__leftSide__margin{
            margin-top: 20px !important;
            width: 100%;
        }
        .post__leftSide__save{
            margin-top: 20px !important;
            display: flex !important; 
            justify-content: center !important;
            align-items: center !important;
        }
        .post__leftSide__save__find{
            width: 90px !important;
            border-radius: 4px !important;
            background-color: black !important;
            color: white !important;
            display: flex !important; 
            justify-content: center !important;
            align-items: center !important;
            margin: 0 8px !important;
        }
    }
    &__rightSide{

        &__search{
            padding-top: 20px;
        }
        &__list{
            overflow-y:auto;
            width: 300px;
            padding: 20px;
            height: 300px;
        }
    }
}
.delete{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    &__title{
        margin-bottom: 25px;
        font-weight: 700;
        font-size: medium;
    }
    &__find{
        width: 90px !important;
        border-radius: 4px !important;
        background-color: black !important;
        color: white !important;
        display: flex !important; 
        justify-content: center !important;
        margin: 0 8px !important;
    }
}
