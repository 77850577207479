.deleteModal {
    .header {
      padding-top: 15px;
      margin: 0 30px;
      font-size: 18px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #E5E5E5;
    }
  
    .body {
      padding: 15px 30px 15px 30px;
      min-height: 60px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
  
      .btns {
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }