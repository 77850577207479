.main_page_wrap {
  position: relative;
  padding: 20px;

  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-left: 250px;

  .main_content_wrap {
    flex-grow: 1;

    .cards_wrap {
      display: flex;
      width: 100%;
      flex-wrap: wrap;

      .item {
        margin: 8px;
        width: 390px;
        height: 420px;
        transition: transform 0.1s ease-in-out; 
      }
      
      .item:hover {
        box-shadow: 0 0 20px rgb(128, 161, 129); 
        
      }
    }

    .underTableBlock {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .table_func {
        margin: 30px 0;
        width: 310 + px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .touched {
        color: #868e96;
      }
    }
  }

  .footer {
    padding: 20px;
    // width: calc(100% - 130px);
    width: 100%;

    .footer_description {
      font-family: Segoe UI;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      color: #868e96;
      margin-top: 30px;
    }
  }
}

.modeBtn_black {
  background: black !important;
  color: white !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer !important;
  height: 32px !important;
  width: 180px !important;
  border-radius: 5px !important;
}

.modeBtn_white {
  background: white !important;
  color: black !important;
  border: 1px solid black !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer !important;
  height: 37px !important;
  width: 180px !important;
  border-radius: 5px !important;
  margin-right: 8px !important;
}

.applicationFilters {
  display: flex;
  width: 100%;
  margin-bottom: 16px;
  margin-left: 8px;
  gap : 8px;
}

.datePicker {
  max-width: 517px;
  margin-left: 8px;
}

@media screen and (max-width: 1024px) {
  .main_page_wrap {
    margin-left: 0;
  }
}

@media screen and (max-width: 768px) {
  .main_page_wrap {
    padding: 3%;

    .main_content_wrap {
      .cards_wrap {
        .item {
          margin: 8px;
          width: 300px;
          height: 440px;
        }
      }
    }
  }
  .applicationFilters {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 16px;
    margin-left: 8px;
  }
}

@media screen and (max-width: 425px) {
  .main_page_wrap {
    padding: 3%;

    .main_content_wrap {
      .cards_wrap {
        justify-content: unset;
        align-items: center;
        flex-wrap: unset;
        flex-direction: column;
        .item {
          margin: 8px;
          width: 100%;
          height: 367px;
        }
      }
    }
  }
  .applicationFilters {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 16px;
    margin-left: unset;
  }

  .datePicker {
    margin-left: 0px;
  }
}
