.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #F8F9FA;
  padding: 0 10px;
  border-bottom: 1px solid #CED4DA;;
  min-height: 50px;

  .item {
    display: flex;
    align-items: center;

    svg {
      margin-left: 4px;
      width: 14px;
    }

    .circle {
      margin-left: 8px;
      width: 10px;
      height: 10px;
      border-radius: 25px;
    }
  }
}

