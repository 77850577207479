.container {
  top: 0;
  position: absolute;
  z-index: 15000000;
  background: #FFFFFF;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 340px;
  height: 115px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .date {
      font-family: SF Pro Display;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;

      color: #000000;
    }

    svg path {
      fill: #000c17;
    }
  }

  .items {
    padding: 10px;
    height: 90px;
    width: 300px;

    .item {
      width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #000c17;
      z-index: 3;
      background: white;

      &:hover {
        z-index: 20;
        background: white;

      }
    }

  }
}