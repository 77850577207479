.item {
  padding: 0 18px;
  height: 38px;
  background: #ced4da;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px 5px 0 0;
  margin-left: 5px;
  border: 1px solid #ced4da;
  border-bottom: none;
  &:first-child {
    margin-left: 0;
  }
}

.active {
  background: white;
  border: 1px solid #ced4da;
  border-bottom: none;
}

@media screen and (max-width: 425px) {
  .item {
    padding: 0 1%;
    margin-left: 2px;
  }
}
