.container {
  width: 400px;

  .content {
    padding: 30px;
  }

  .footer {
    padding: 0 30px 30px 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .btns {
      width: 230px;
      display: flex;
      justify-content: space-between;
    }
  }
}

@media screen and (max-width: 425px) {
  .container {
    width: 315px;
    .content {
      padding: 3%;
    }
    .footer {
      padding: 0 3% 3% 3%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .btns {
        width: 100%;
        display: flex;
        justify-content: space-around;
      }
    }
  }
}
