.main {
  padding: 20px;
  margin-left : 250px;

  .wrap {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;

    .pageChangerBlock {
    }
  }
}

@media screen and (max-width: 1024px) {
  .main { 
    margin-left: 0;
  }
}

@media screen and (max-width: 425px) {
  .main {
    padding: 3%;
    .wrap {
      flex-direction: column;
      margin-top: 10px;
    }
  }
}
