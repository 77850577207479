.wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  & > * {
    margin: 8px;
  }
}

.btn {
  margin-top: 31px;
  margin-bottom: 31px;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 9px 18px;
}

.btn:hover {
  background: #0085ff;
  border: 1px solid #0085ff;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
}

/*button:active {
    background: #E5F3FF;
    border: 1px solid #0085FF;
    box-sizing: border-box;
    border-radius: 5px;
    color: #0085FF;
}*/
.btn_img:hover {
  svg path {
    fill: #fff;
  }
}

@media screen and (max-width: 425px) {
  .wrapper {
    flex-wrap: unset;
    flex-direction: column;
    & > * {
      width: 100%;
    }
  }
}
