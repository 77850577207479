.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .title {
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    color : black;
    font-size: 1.7rem;
    line-height: 40px;
    display: flex;
    align-items: center;

    .pages {
      position: absolute;
      top: 105%;
      left: 0%;
      background-color: white;
      border-radius: 4px;
      width: 100%;
      z-index: 10;

      .item {
        padding: 3%;
        color: #212529;
        font-size: 0.8rem;
        background-color: #ebebeb;
        &:first-child {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
        &:last-child {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }
        a {
          color: #212529;
          width: 100%;
        }
      }
    }

    .back {
      transform: rotate(180deg);
    }

    .none {
      display: none;
    }
  }

  .input {
    width: 70%;
    height: 37px;
    border: 1px solid #ced4da;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 9px 18px;
    font-size: 14px;
    line-height: 19px;

    &::placeholder {
      font-family: Segoe UI;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: #bfbfbf;
    }
  }

  .button {
    width: 98px;
    height: 38px;
  }
}

@media screen and (max-width: 425px) {
  .container {
    flex-direction: column;

    .button {
      width: 100%;
    }
    .input {
      width: 100%;
      margin-bottom: 4px;
    }

    a {
      width: 100%;
    }
  }
}
