.container {
  padding: 20px;
  margin-left: 250px;

  &__mainInfo {
    margin-top: 31px;

    &__title {
      font-size: 13px;
      color: #868E96;
    }

    &__input {
      margin-bottom: 20px;
    }
  }

  &__role {

    &__selector {

      &__title {
        font-size: 13px;
        color: #868E96;
      }
    }

    &__formviews {
      margin-top: 20px;
      &__title {
        font-size: 13px;
        color: #868E96;
      }
    }
  }

  &__workers {
    margin-top: 31px;

    &__title {
      font-size: 13px;
      color: #868E96;
    }

    &__list {
      margin-top: 50px;

      &__item {
        display: flex;
        width: 100%;
        max-width: 679px;
        align-items: center;
        justify-content: space-between;
        min-height: 40px;
        border-bottom: 1px solid #CED4DA;

        &__deleteBlock {
          padding-left: 8px;
          border-left: 1px solid #868E96;
          cursor: pointer;
        }
      }
    }
  }


  &__buttons {
    margin-top: 31px;
    display: flex;
    padding-top: 14px;
    border-top: 1px solid #CED4DA;
  }
}

.areYouSureModal {
  padding:20px;
  display:flex;
  align-items: center;
  justify-content: flex-end;
}