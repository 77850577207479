.main_page_wrap {
  position: relative;
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-left: 250px;
}

.title_wrap {
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #868e96;
  margin-top: 10px;
}

.date_wrap {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #212529;
  margin-top: 5px;
}

.params_wrap {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: #868e96;
}

.content_wrap {
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 10px 0 60px;
  padding: 10px;
}

.description_wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  line-clamp: 4;
  box-orient: vertical;

  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #212529;
}

.expand_btn {
  margin-left: 5px;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #0275d8;
}

.footer_btn_wrap {
  display: flex;
  align-items: center;
  margin-top: 12px;

  div {
    margin-left: 6px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.comments {
  position: relative;

  .subPages {
    position: absolute;
    top: -37px;
    display: flex;
  }

  .comments_block {
    padding: 30px;
    border: 1px solid #ced4da;
    border-radius: 0 5px 5px 5px;

    font-size: 13px;
    color: #868e96;

    .title {
      margin-bottom: 16px;
    }

    .item {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }

      .title {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        .author {
          font-size: 14px;
          font-weight: 700;
          color: black;
        }

        .date {
          margin-left: 4px;
          border-left: 1px solid #868e96;
          padding-left: 4px;
        }
      }

      .content {
        word-wrap: break-word;
      }
    }

    .btns {
      margin-top: 16px;
      display: flex;
      align-items: center;
      width: 16%;
      justify-content: space-between;
    }
  }

  .history_block {
    padding: 30px;
    border: 1px solid #ced4da;
    border-radius: 0 5px 5px 5px;
  }

  .actions_block {
    padding: 30px;
    border: 1px solid #ced4da;
    border-radius: 0 5px 5px 5px;

    .actionsFilters {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }

    .input {
      width: 679px;
      height: 37px;
      border: 1px solid #ced4da;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 9px 18px;
      font-size: 14px;
      line-height: 19px;
      margin-top: 18px;

      &::placeholder {
        font-family: Segoe UI;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: #bfbfbf;
      }
    }
  }
}

@media screen and (max-width: 1024px) {

  .main_page_wrap {
    margin-left: 0;
  }

  .comments {
    .actions_block {
      .input {
        width: 30%;
      }

      .actionsFilters {}
    }
  }
}

@media screen and (max-width: 425px) {
  .main_page_wrap {
    padding: 3%;
  }

  .content_wrap {
    border: 1px solid #ced4da;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 3%;
  }

  .footer_btn_wrap {
    justify-content: unset;
    width: 100%;
    margin-top: 3%;
    flex-wrap: wrap;

    div {
      margin: 4px;
    }
  }

  .comments {
    position: relative;

    .subPages {
      position: absolute;
      top: -37px;
      display: flex;
    }

    .comments_block {
      padding: 3%;

      .btns {
        width: 100%;
      }
    }

    .history_block {
      padding: 3%;
    }

    .actions_block {
      padding: 3%;

      .input {
        width: 100%;
        margin-bottom: 2%;
      }

      .actionsFilters {
        display: flex;
        flex-direction: column;
      }
    }
  }
}