.body {
  padding: 30px;
  width: 600px;

  .item {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .title {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 8px;
    }

    .value {
      width: 100%;
      max-width: 300px;
    }
  }
}

.footer {
  padding: 0 30px 30px 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .btns {
    width: 210px;
    display: flex;
    justify-content: space-between;
  }
}