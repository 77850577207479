.input_header {
  width: 90%;
  height: 38px;
  background: #f1f3f5;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  outline: none;
  border: none;
  padding: 10px 0;
  padding-left: 10px;
  margin-right: 20px;

  &::placeholder {
    color: #212529;
  }
}

.findApplication {
  width: 120px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #212529 !important;
  color: white !important;
  border-radius: 4px !important;

  &:hover {
    background: #212529 !important;
    color: white !important;
  }
}

.search {
  display: flex;
  align-items: center;
  width: 50%;

  .nested {
    background: #f1f3f5;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    width: 38px;
    height: 38px;
  }

  .searchBlock {
    display: flex;
    width: 100%;
  }
}

.search_btn {
  padding: 10px 0 10px 10px;
  width: 40px;
  height: 38px;
  border: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: #f1f3f5;
}

.main_content_wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.notification_login {
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
}

.exitBlock {
  border: 1px solid #c4c4c4;
  left: 90%;
  top: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 15;
  position: absolute;
  background: white;
  box-shadow: 4px 10px 10px rgba(0, 0, 0, 0.1);
  width: 60px;
  height: 40px;

  a {
    color: #000c17;
  }
}

.notification {
  position: relative;

  &:hover {
    cursor: pointer;
  }

  .red_circle_notification {
    display: block;
  }
}

.red_circle_notification {
  width: 15px;
  height: 16px;
  border-radius: 50%;
  color: #fff;
  background-color: #fa5252;
  text-align: center;

  font-size: 3px;
  position: absolute;
  top: -2%;
  left: 51%;
  display: none;
}
@media screen and (max-width: 425px) {
  .buttons {
    width: 100%;
    .findApplication {
      width: 100%;
    }
  }

  .input_header {
    width: 90%;
    height: 38px;
    background: #f1f3f5;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    outline: none;
    border: none;
    padding: 10px 0;
    padding-left: 10px;
    margin-right: unset;
    margin-bottom: 6px;

    &::placeholder {
      color: #212529;
    }
  }
  .main_content_wrap {
    align-items: flex-start;
  }

  .search {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .exitBlock {
    left: unset;
    right: 5%;
  }
}
