.main_wrap {
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px 2px rgba(34, 60, 80, .2);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 3%;
  overflow: hidden;
  text-overflow: ellipsis;

  .status_additionaly_wrap {
    // display: flex;
    // flex-direction: row;
    // justify-content: space-between;
    // align-items: flex-start;
    // position: relative;
    // width: 100%;
    // height: 30%;

    .status {
      width: 100%;

      &_changer {
        position: absolute;
        top: 30%;
        left: 60%;
        width: 100%;
        max-width: 160px;
        background: #ffffff;
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 5px;

        .item {
          min-height: 45px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 15px;
          border-bottom: 1px solid #ced4da;
          cursor: pointer;

          &:hover {
            &:first-child {
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
            }

            &:last-child {
              border-bottom-left-radius: 5px;
              border-bottom-right-radius: 5px;
            }

            background: #868e96;
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }

    .functions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 110px;

      .icon {
        padding: 0;
        margin-right: 6px;

        svg {
          width: 18px;
          height: 18px;
        }
      }

      .likeIcon {
        padding: 0;
        margin-right: 6px;

        svg {
          width: 18px;
          height: 18px;

          path {
            fill: #878787;
          }
        }
      }

      .likeIcon_active {
        padding: 0;
        margin-right: 6px;

        svg {
          width: 18px;
          height: 18px;

          path {
            fill: red;
          }
        }
      }

      .threePoints {
        padding: 0;

        svg {
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  .title_card {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 700;
    font-size: 1.1rem;
    color: #000;
  }

  .description {
    color: #000;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 0.7rem;
  }

  .executor_wrap {
    display: flex;
    border-bottom: 1px solid #dadada;
    padding: 2px 0;
    margin-bottom: 4px;
    justify-content: space-between;
    align-items: center;

    .executor_wrap_title {
      font-family: Segoe UI;
      font-style: normal;
      font-size: .9rem;
      color: #7a7979;
      margin-right: 5px;
      width: 45%;
    }

    .executor {
      width: 54%;
      color: #000;
      font-size: .8rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .tags_card {
    display: flex;
  }
}