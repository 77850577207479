.body {
  padding: 30px;
  width: 500px;

  .item {
    width: 400px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:first-child {
      margin-bottom: 15px;
    }

    .title {
      font-weight: 500;
      font-size: 15px;
      margin-bottom: 8px;
    }

    .value {
      width: 220px;
    }
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: 0 30px 15px 30px;

  .btns {
    display: flex;
    justify-content: space-between;
    width: 200px;
  }
}

@media screen and (max-width: 425px) {
  .body {
    padding: 3%;
    width: 300px;
    .item {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      .title {
        font-weight: 600;
      }
      .value {
        width: 100%;
      }
    }
  }
}
