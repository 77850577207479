.page {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.sidebar {
  // flex: 0 0 310px;
  flex: 0 0 250px;
  background: #212529;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 250px;
}

.sidebar_item {
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  text-decoration: none;
  margin-bottom: 8px;
}

.sidebar_item_wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;

  div:first-child {
    margin-right: 8px;
  }
}

.sidebar_item_active {
  background-color: #343a40;
}

.content {
  flex: 1 1 auto;
  min-height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
}

.main_logo_wrap {
  margin-top: 33px;
  margin-bottom: 81px;
}

.sidebar_item {
  width: 70%;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
}

.sidebar_item_main_wrap {
  height: 31px;
  padding-right: 25px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.root {
  background: #212529 !important;
  color: #fff !important;
  width: 100%;
  padding: 10% 0 10% 0 !important;

  a {
    width: 100%;
    display: flex;
    align-items: center;
    color: #fff !important;
  }
}

.list_item {
  display: flex;
  align-items: center;
  min-height: 40px;
}

.small_item {
  font-weight: normal;
  font-size: 12px !important;
  line-height: 16px;
}

.nested_column {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
}

.sideBarHeader {
  padding: 3%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.notification_login {
  display: flex;
  align-items: center;
}

.exitBlock {
  border: 1px solid #c4c4c4;
  right: 5%;
  top: 6%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 15;
  position: absolute;
  background: white;
  box-shadow: 4px 10px 10px rgba(0, 0, 0, 0.1);
  width: 60px;
  height: 40px;
  border-radius: 5px;

  a {
    color: #000c17;
  }
}

.authMenuBlock {
  display: flex;
  align-items: center;
}

@keyframes ani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.red_circle_notification {
  color: #fff;
}

@media screen and (max-width: 1024px) {
  .page {
    flex-direction: column;
    min-height: unset;
  }
  .root {
    padding: 3% !important;
    animation: ani 2.5s forwards;
  }

  .sidebar {
    flex: unset;
    min-height: unset;
    position: relative;
    width : 100%;
    top: 0;
    left: 0;
    right: 0;
  }
  .main_logo_wrap {
    margin-top: unset;
    margin-bottom: unset;
  }
}

.active__sidebar {
  background-color: #45494d;
  transition: background-color 0.1s ease-in-out, transform 0.1s ease-in-out;
  transform: translateX(0);
}

.active__sidebar:hover {
  background-color: #6d7277;
  transform: translateX(2px);
}

.notactive__sidebar {
  transition: background-color 0.1s ease-in-out, transform 0.1s ease-in-out;
  transform: translateX(0);
}

.notactive__sidebar:hover {
  transform: translateX(2px);
}