.container {
  .headers {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    border: 1px solid #ced4da;
    box-sizing: border-box;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    margin-top: 31px;
    padding: 15px 30px;
  }
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  border: 1px solid #ced4da;
  border-top: none;
  height: 51px;

  overflow: hidden;
  text-overflow: ellipsis;

  &:last-child {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:hover {
    cursor: text;
  }

  .item {
    height: 21px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: 24px;
  }
}

.card {
  background-color: #ebebeb;
  border-radius: 4px;
  margin: 4px;
  width: 100%;
  padding: 2%;
  .title {
    font-size: 0.9rem;
    font-weight: 600;
  }
  .value {
    word-break: break-all;
  }
}

@media screen and (max-width: 425px) {
  .body {
    display: flex;
    flex-wrap: wrap;
  }
}
