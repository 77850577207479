.main_page_wrap {
  position: relative;
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: calc(100vw - 250px);
  margin-left: 250px;

  .filters {
    display: flex;
    align-items: flex-end;
    width: 50%;
  }

  .title_wrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .title_content {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    border: 1px solid #ced4da;
    box-sizing: border-box;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    margin-top: 31px;
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    padding: 1% 2%;
  }

  .btn_download {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 31px;
  }
}

.content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 1024px) {
  .main_page_wrap {
    margin-left: 0;
  }
}

@media screen and (max-width: 768px) {
  .main_page_wrap {
    padding: 3%;
  }
  .filters {
    width: 80% !important;
  }
}

@media screen and (max-width: 425px) {
  .content {
    justify-content: center;
  }

  .filters {
    flex-direction: column;
    width: 100% !important;
  }
}
