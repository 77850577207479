.body {
  padding: 24px;

  .row {
    margin-top: 8px;
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 15px;
      font-weight: 600;
    }
  }

  .buttons {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 205px;
    }
  }
}

@media screen and (max-width: 425px) {
  .body {
    padding: 3%;
    .row {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
  }
}
