.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #868e96;
}

@media screen and (max-width: 425px) {
  .container {
    flex-direction: column;
  }
}
