.main_page_wrap {
  position: relative;
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-left: 250px;
}

.title_basic_info {
  font-family: SF Pro Display, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #868e96;
  margin-top: 31px;
}

.basic_info_wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;

  .select {
    margin-top: 18px;
  }

  .input {
    width: 679px;
    height: 37px;
    border: 1px solid #ced4da;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 9px 18px;
    font-size: 14px;
    line-height: 19px;
    margin-top: 18px;

    &::placeholder {
      font-family: Segoe UI;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: #bfbfbf;
    }
  }
}

.description_basic_info {
  padding: 9px 18px;
  width: 679px;
  height: 150px;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 15px;
  font-size: 14px;
  line-height: 19px;
  resize: none;

  &::placeholder {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #bfbfbf;
  }
}

.main_download_wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 35px;
}

.download_btn {
  cursor: pointer;
  width: 187px;
  height: 38px;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 5px;
  margin-right: 20px;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #212529;

  svg path {
    width: 24px;
    height: 16px;
  }
}

.download_wrap {
  display: flex;
}

.download_title {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  margin-right: 20px;
}

.title_file {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #868e96;
}

.basic_info_footer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 37px;
  width: 680px;
  margin-bottom: 100px;
}

.basic_info_footer_first_part {
  display: flex;
}

.basic_info_footer_second_part {
  display: flex;
  align-items: center;
}

.parameters_title {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
}

.margin_right {
  margin-right: 15px;
}

.attached_file {
  color: #0275d8;
}

.vertical_line {
  width: 4px;
  height: 19px;
  margin-right: 19px;

  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  background-color: #868e96;
}

.footer {
  margin-top: 31px;
  display: flex;
}

@media screen and (max-width: 1024px) {
  .main_page_wrap {
    margin-left: 0;
  }
}

@media screen and (max-width: 425px) {
  .main_page_wrap {
    padding: 3%;
  }

  .basic_info_wrap {
    width: 100%;

    .input {
      width: 100%;
    }
  }

  .description_basic_info {
    width: 100%;
  }

  .input {
    width: 100%;
  }

  .main_download_wrap {
    width: 100%;
  }

  select {
    width: 100% !important;
  }
}