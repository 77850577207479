.button {
  box-sizing: border-box;
  min-width: 0px !important;

  & span:first-child {
    white-space: nowrap;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    letter-spacing: 0.02em;
    text-transform: none;
  }

  & span:last-child {
  }

  & svg {
    width: 20px;
    height: 20px;
  }
}

.noShadow {
  box-shadow: none;
  background: #fff !important;

  & span:first-child {
    font-weight: 500;
    font-size: 0.9rem;
    letter-spacing: 0.02em;
    color: #3A3A3A;
  }
}

.black {
  background: #212529 !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  border-radius: 4px;

  & span:first-child {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    text-transform: none;
  }

  & span:last-child {
  }
}

.white {
  background: #fff !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);

  & span:first-child {
    font-weight: 500;
    font-size: 0.9rem;
    letter-spacing: 0.02em;
    color: #3A3A3A;
  }
}

.red {
  background: #FA5252 !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);

  & span:first-child {
    font-weight: 500;
    font-size: 0.9rem;
    letter-spacing: 0.02em;
    color: #FFFFFF;
  }
}

.disabled {
  opacity: 0.5 !important;
  pointer-events: none;

  & span {
    opacity: 0.5 !important;
  }
}

.active {
  background: #E5F3FF !important;
  border: 1px solid #0085FF !important;
  color: #0085FF !important;;
}